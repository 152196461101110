import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import media from "./Media";
import { Card, Text, WrapFlexContainer } from "@lewisblackwood/component-library";

export const BlogImage = styled.div`
  background: ${props => `url(${props.image}) center`};
  background-size: cover;
  border-radius: 8px 8px 0 0;
  height: 100%;
  left: 0;
  max-height: 240px;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const HeaderImage = styled.div`
  background: ${props => `url(${props.image}) center`};
  background-size: cover;
  height: 540px;
  width: 100%;
  margin-bottom: ${props => props.theme.spacing.xlarge};
  border-radius: 8px;

  ${media.small`
    margin-bottom: ${props => props.theme.spacing.large};
    height: 400px;
  `};

  ${media.xsmall`
    height: 255px;
  `};
`;

export const BlogCard = props => (
  <Card as="article" style={{ width: "100%", height: "100%" }}>
    <Link to={props.url} style={{ height: "100%" }}>
      <BlogImage image={props.image || placeholder} />
      <WrapFlexContainer height="100%" flexDirection="column">
        <div style={{ height: "240px", width: "100%" }} />
        <Text size="small" mb="xsmall" colour="grey" center>
          {props.tag || "General"}
        </Text>
        <Text size="large" colour="black" mb="xsmall" bold center>
          {props.title}
        </Text>
        <Text mb="none" center style={{ marginTop: "auto" }}>
          {props.author}
        </Text>
      </WrapFlexContainer>
    </Link>
  </Card>
);
