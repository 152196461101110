import React from "react";
import { GridContainer } from "@lewisblackwood/component-library";
import { FlexSection } from "../layout";
import { BlogCard } from "../../styledComponents/Blog";

const Posts = props => {
  const posts = props.posts;

  return (
    <FlexSection column="2">
      <GridContainer itemWidth="330px" gridGap="xlarge">
        {posts.map(edge => (
          <BlogCard
            key={edge.node.id}
            id={edge.node.id}
            title={edge.node.frontmatter.title}
            image={edge.node.frontmatter.feature_image}
            tag={edge.node.frontmatter.primary_tag_name}
            author={edge.node.frontmatter.author_name}
            url={`/blog${edge.node.fields.slug}`}
          />
        ))}
      </GridContainer>
    </FlexSection>
  );
};

export default Posts;
