import React from "react";
import { FlexSection, Title } from "../layout";
import { H1, H2 } from "@lewisblackwood/component-library";

const Hero = () => (
  <FlexSection>
    <Title>
      <H1
        size="small"
        sizeMobile="small"
        mb="xsmall"
        colour="grey"
        center
        style={{ fontWeight: 400 }}
      >
        Blog
      </H1>
      <H2 size="xxlarge" sizeMobile="large" center>
        Give new joiners a better experience
      </H2>
    </Title>
  </FlexSection>
);

export default Hero;
