import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Helmet from "react-helmet";

// Components
import Hero from "../components/blog/Hero";
import Posts from "../components/blog/Posts";

const Blog = ({ data }) => {
  const posts = data.allMarkdownRemark.edges;

  return (
    <Layout>
      <Helmet>
        <title>Blog</title>
        <meta
          name="description"
          content="Read more about the latest things that have been happening at Personably"
        />
      </Helmet>
      <Hero />
      <Posts posts={posts} />
    </Layout>
  );
};

export default Blog;

export const pageQuery = graphql`
  query GhostPostQuery {
    allMarkdownRemark(sort: {fields: frontmatter___published_at, order: DESC}) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            feature_image
            description
            published_at
            updated_at
            primary_tag_name
            primary_author_name
            primary_author_twitter
          }
          html
        }
      }
    }
  }
`;

// // This page query loads all posts sorted descending by published date
// // The `limit` and `skip` values are used for pagination
// export const pageQuery = graphql`
//   query GhostPostQuery($limit: Int, $skip: Int) {
//     allGhostPost(
//       sort: { order: DESC, fields: [published_at] }
//       limit: $limit
//       skip: $skip
//     ) {
//       edges {
//         node {
//           id
//           slug
//           title
//           feature_image
//           primary_tag {
//             id
//             name
//           }
//           authors {
//             id
//             name
//           }
//         }
//       }
//     }
//   }
// `;
